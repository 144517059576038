import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"


const get_ReportDetailsLedger = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.LEDGER.RINCIAN_BUKU_BESAR, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


const get_ReportSummaryLedger = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.LEDGER.RINGKASAN_BUKU_BESAR, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportListAccountEstimated = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.LEDGER.LIST_AKUN, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ReportGeneralJournal = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.REPORT.LEDGER.JURNAL_UMUM, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_ReportDetailsLedger,
  get_ReportGeneralJournal,
  get_ReportListAccountEstimated,
  get_ReportSummaryLedger
}